@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/vendor';
@import 'libs/breakpoints';
@import 'libs/html-grid';

/*
	Forty by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

/* Banner */

	#banner {
		body.is-preload & {
			&:after {
				opacity: 0.85;
			}

			> .inner {
				@include vendor('filter', 'none');
				@include vendor('transform', 'none');
				opacity: 1;
			}
		}
	}